/* CreateEvent.css */
.create-event-container {
  margin: 20px 0;
  padding: 20px;
  background-color: #fcfcfc;
  color: #5d3dfd;
  border: 2px solid #5d3dfd;
  border-radius: 10px;
}

.create-event-container h3 {
  margin-bottom: 10px;
}

.create-event-container input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #c42fad;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

.create-event-container button {
  background-color: #f82885;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.create-event-container button:hover {
  background-color: #c42fad;
}
