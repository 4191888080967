/* src/components/common/Podium/Podium.css */

:root {
  --white: #fcfcfc;
  --blue: #5d3dfd;
  --purple: #9036d5;
  --pink-purple: #c42fad;
  --bright-pink: #f82885;
  --dark-overlay: rgba(0, 0, 0, 0.85);
  --gold: #ffd700;
  --silver: #c0c0c0;
  --bronze: #cd7f32;
  --shadow-color: rgba(0, 0, 0, 0.3);
  --font-family: 'Montserrat', sans-serif;
  --container-bg: linear-gradient(135deg, rgba(93,61,253,0.8), rgba(144,54,213,0.8));
  --glow-color: rgba(248, 40, 133, 0.6);
}

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

/* Overlay Styles */
.podium-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark-overlay);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500;
  overflow: hidden;
}

/* Podium Container Styles */
.podium-container {
  background: var(--container-bg);
  padding: 60px 50px;
  border-radius: 25px;
  text-align: center;
  position: relative;
  width: 90%;
  max-width: 1000px;
  box-shadow: 0 12px 30px var(--shadow-color);
  animation: scaleIn 0.7s ease-out forwards;
  transform: scale(0.8);
  color: var(--white);
}

/* Scale In Animation */
@keyframes scaleIn {
  to {
    transform: scale(1);
  }
}

/* Heading Styles */
.podium-container h2 {
  font-family: var(--font-family);
  font-size: 3em;
  color: var(--bright-pink);
  margin-bottom: 60px;
  text-shadow: 3px 3px 7px rgba(0, 0, 0, 0.5);
  animation: fadeIn 1s ease-out forwards;
}

/* Fade In Animation for Heading */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Podium Layout Styles */
.podium {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 60px;
  width: 100%;
  max-width: 1000px;
  margin-bottom: 60px;
  flex-wrap: wrap;
  perspective: 1000px;
}

/* Podium Place Styles */
.podium-place {
  width: 200px;
  background-color: var(--white);
  border-radius: 20px 20px 0 0;
  padding: 35px 20px;
  text-align: center;
  position: relative;
  box-shadow: 0 10px 25px var(--shadow-color);
  transition: transform 0.4s, box-shadow 0.4s;
  transform: rotateX(0deg) rotateY(0deg);
  animation: tiltIn 0.5s forwards;
}

.podium-place:hover {
  transform: rotateX(10deg) rotateY(-10deg);
  box-shadow: 0 15px 30px var(--shadow-color);
}

/* Tilt In Animation */
@keyframes tiltIn {
  from {
    transform: rotateX(90deg) rotateY(90deg);
    opacity: 0;
  }
  to {
    transform: rotateX(0deg) rotateY(0deg);
    opacity: 1;
  }
}

/* Specific Podium Places */
.podium-1 {
  background-color: var(--gold);
  height: 300px;
  position: relative;
}

.podium-2 {
  background-color: var(--silver);
  height: 270px;
  position: relative;
}

.podium-3 {
  background-color: var(--bronze);
  height: 240px;
  position: relative;
}

/* Podium Rank Badge */
.podium-rank {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--white);
  color: var(--purple);
  width: 70px;
  height: 70px;
  line-height: 70px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 1.5em;
  box-shadow: 0 4px 10px var(--shadow-color);
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.podium-rank:hover {
  background-color: var(--bright-pink);
  color: var(--white);
  transform: scale(1.1);
}

/* Podium Rank Icons */
.podium-rank svg {
  margin-right: 5px;
}

/* Podium Name Styles */
.podium-name {
  margin-top: 40px;
  font-family: var(--font-family);
  font-size: 1.6em;
  color: var(--purple);
  font-weight: 600;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

/* Podium Time Styles */
.podium-time {
  margin-top: 15px;
  font-family: var(--font-family);
  font-size: 1.2em;
  color: var(--pink-purple);
}

/* Restart Button Styles */
.restart-button {
  padding: 20px 40px;
  font-size: 1.6em;
  background-color: var(--bright-pink);
  color: var(--white);
  border: none;
  border-radius: 35px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 8px 20px var(--shadow-color);
  font-family: var(--font-family);
}

.restart-button:hover {
  background-color: var(--pink-purple);
  transform: scale(1.05);
  box-shadow: 0 12px 25px var(--shadow-color);
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .podium-container {
    padding: 50px 35px;
  }

  .podium-container h2 {
    font-size: 2.8em;
  }

  .podium {
    gap: 50px;
  }

  .podium-place {
    width: 180px;
    padding: 30px 15px;
  }

  .podium-rank {
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 1.4em;
  }

  .podium-name {
    font-size: 1.5em;
  }

  .podium-time {
    font-size: 1.1em;
  }

  .restart-button {
    font-size: 1.5em;
    padding: 18px 35px;
  }
}

@media (max-width: 992px) {
  .podium-container {
    padding: 45px 30px;
  }

  .podium-container h2 {
    font-size: 2.6em;
  }

  .podium {
    gap: 40px;
  }

  .podium-place {
    width: 160px;
    padding: 25px 12px;
  }

  .podium-rank {
    width: 55px;
    height: 55px;
    line-height: 55px;
    font-size: 1.3em;
  }

  .podium-name {
    font-size: 1.4em;
  }

  .podium-time {
    font-size: 1em;
  }

  .restart-button {
    font-size: 1.4em;
    padding: 16px 30px;
  }
}

@media (max-width: 768px) {
  .podium {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .podium-place {
    width: 140px;
    padding: 20px 10px;
  }

  .podium-rank {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 1.2em;
  }

  .podium-1 {
    height: 220px;
  }

  .podium-2 {
    height: 190px;
  }

  .podium-3 {
    height: 160px;
  }

  .restart-button {
    width: 80%;
    padding: 16px;
    font-size: 1.3em;
  }
}

@media (max-width: 480px) {
  .podium-container {
    padding: 35px 25px;
  }

  .podium-container h2 {
    font-size: 2.2em;
  }

  .podium {
    gap: 20px;
  }

  .podium-place {
    width: 120px;
    padding: 15px 8px;
  }

  .podium-rank {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 1.1em;
  }

  .podium-name {
    font-size: 1.2em;
  }

  .podium-time {
    font-size: 0.95em;
  }

  .restart-button {
    width: 100%;
    padding: 14px;
    font-size: 1.2em;
  }
}
