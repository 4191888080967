/* src/components/GameConfig/GameConfig.css */

.game-config {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.header h1 {
  font-size: 2em;
  color: #343a40;
}

.form-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.btn-back {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  width: fit-content;
}

.btn-back:hover {
  background-color: #0056b3;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-group label {
  font-weight: bold;
  color: #495057;
}

.form-group input[type="text"],
.form-group select,
.form-group input[type="file"] {
  padding: 8px;
  font-size: 1em;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.form-group input[type="file"] {
  padding: 3px;
}

.form-group input[type="color"] {
  width: 100px;
  height: 50px;
  padding: 0;
  border: none;
  cursor: pointer;
}

.form-group input[type="color"]:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(93, 61, 253, 0.5);
}

.image-previews {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.btn-delete {
  background-color: transparent;
  border: none;
  color: red;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 1.2em;
}

.image-preview {
  position: relative;
  display: inline-block;
  margin: 10px;
}

.image-preview img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 2px solid #ccc;
  border-radius: 5px;
}

.preview {
  margin-top: 10px;
  position: relative;
  width: 100px;
  height: 100px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  overflow: hidden;
}

.preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn-save {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  align-self: center;
}

.btn-save:hover {
  background-color: #218838;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  outline: none;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.error-message {
  color: red;
  font-weight: bold;
}

/* Customization Fields */
.customization-fields {
  margin-top: 20px;
}

.customization-fields h3 {
  font-size: 1.8em;
  color: #343a40;
  margin-bottom: 15px;
  border-bottom: 2px solid #ced4da;
  padding-bottom: 5px;
}

.color-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
}

.color-inputs .form-group {
  flex: 1 1 200px;
}

.logo-options {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.logo-options label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.logo-options input[type="radio"] {
  margin-right: 10px;
}

.preview {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.preview img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 5px;
  margin-right: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.preview .btn-delete {
  background-color: #dc3545;
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
}

.preview .btn-delete:hover {
  background-color: #c82333;
}

/* Live Preview Styles */
.live-preview {
  margin-top: 40px;
}

.live-preview h3 {
  font-size: 1.6em;
  color: #343a40;
  margin-bottom: 15px;
}

.live-preview .preview-container {
  border: 1px solid #ced4da;
  border-radius: 10px;
  padding: 20px;
  background-color: #ffffff;
}

.live-preview .game-layout {
  display: flex;
}

.live-preview .gameboard-preview {
  flex: 1;
  background-color: var(--gameboard-background-color, #ffffff);
  padding: 20px;
  border-radius: 0 0 15px 15px;
  margin-left: 15px;
}

.live-preview .cards-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.live-preview .card-preview {
  width: 60px;
  height: 80px;
  border: 3px solid var(--card-border-color, #000000);
  border-radius: 5px;
  background-color: #ccc;
  transition: border-color 0.3s;
}

.live-preview .card-preview:hover {
  border-color: #343a40;
}

.live-preview .puzzle-preview {
  width: 100%;
  height: 200px;
  background-color: #e9ecef;
  border-radius: 5px;
}

/* Responsive Design */
@media (max-width: 600px) {
  .image-preview,
  .preview {
    width: 80px;
    height: 80px;
  }

  .btn-back,
  .btn-save {
    width: 100%;
    justify-content: center;
  }

  .color-inputs {
    flex-direction: column;
  }

  .live-preview .game-layout {
    flex-direction: column;
  }

  .live-preview .gameboard-preview {
    margin-left: 0;
    margin-top: 20px;
    border-radius: 15px;
  }
}

.form-section.disabled {
  opacity: 0.5;
  pointer-events: none;
}
