* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

body {
  background: #f4f4f4;
  color: #333;
}

.home-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}

.hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
  text-align: center;
  background: linear-gradient(135deg, #f44c6c, #ff6b6b);
  position: relative;
  overflow: hidden;
  color: #fff;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("https://source.unsplash.com/random/1920x1080") no-repeat
    center center/cover;
  filter: brightness(50%);
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 2;
  padding: 20px;
  max-width: 800px;
  text-align: center;
}

.hero-logo {
  height: 80px;
  margin-bottom: 20px;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.9;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.hero-text {
  margin-top: 20px;
}

.hero-text h1 {
  font-size: 4em;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: -1px;
  background: linear-gradient(135deg, #ffffff, #f44c6c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-text .hero-subtitle {
  font-size: 1.8em;
  margin-bottom: 20px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
}

.hero-text p {
  font-size: 1.3em;
  margin-bottom: 30px;
  line-height: 1.6;
  opacity: 0.9;
}

.cta-button {
  background: #fff;
  color: #5d3dfd;
  padding: 15px 30px;
  border-radius: 50px;
  text-decoration: none;
  font-weight: 700;
  font-size: 1.2em;
  transition: background 0.3s ease, transform 0.3s ease;
  display: inline-block;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cta-button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: radial-gradient(
    circle,
    rgba(93, 61, 253, 0.3) 0%,
    rgba(93, 61, 253, 0) 70%
  );
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.5s ease;
}

.cta-button:hover::before {
  transform: translate(-50%, -50%) scale(1);
}

.cta-button:hover {
  background: #f0f0f0;
  transform: translateY(-3px);
}

.features-section {
  background: linear-gradient(135deg, #f0f0f0, #ffffff);
  padding: 60px 20px;
  text-align: center;
}

.features-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.feature-card {
  background: #ffffff;
  border-radius: 15px;
  padding: 30px;
  width: 100%;
  max-width: 300px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.15);
}

.feature-icon {
  background: linear-gradient(135deg, #5d3dfd, #c42fad);
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
  color: #ffffff;
  font-size: 2.5em;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease, transform 0.3s ease;
}

.feature-icon:hover {
  background: linear-gradient(135deg, #f82885, #9036d5);
  transform: scale(1.1);
}

h3 {
  font-size: 1.8em;
  margin-bottom: 10px;
  color: #5d3dfd;
}

p {
  font-size: 1.1em;
  color: #333333;
}

.pricing-section {
  padding: 60px 20px;
  background: linear-gradient(135deg, #e3f2fd, #ffffff);
  text-align: center;
}

.pricing-section h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
  color: #3f51b5;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.pricing-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricing-card {
  background: #ffffff;
  border-radius: 15px;
  padding: 40px 20px;
  width: 100%;
  max-width: 350px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  text-align: center;
}

.pricing-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.15);
}

.pricing-header {
  margin-bottom: 20px;
  text-align: center;
}

.pricing-header h3 {
  font-size: 1.8em;
  margin-bottom: 10px;
  color: #3f51b5;
}

.pricing-price {
  font-size: 2em;
  color: #f44336;
}

.pricing-price span {
  font-size: 0.6em;
  color: #757575;
}

.pricing-features {
  list-style: none;
  padding: 0;
  margin: 20px 0;
  text-align: center;
}

.pricing-features li {
  font-size: 1.1em;
  margin-bottom: 10px;
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricing-features li:before {
  content: "✔";
  margin-right: 10px;
  color: #4caf50;
}

.cta-button {
  display: inline-block;
  padding: 12px 24px;
  background: #3f51b5;
  color: #ffffff;
  border-radius: 25px;
  text-decoration: none;
  font-size: 1.2em;
  transition: background 0.3s ease, transform 0.3s ease;
}

.cta-button:hover {
  background: #303f9f;
  transform: translateY(-3px);
}

.footer {
  background: #333;
  color: #fff;
  padding: 40px 20px;
  text-align: center;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-logo img {
  height: 60px;
  margin-bottom: 20px;
}

.footer-nav {
  margin-bottom: 20px;
  justify-content: center;
}

.footer-nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

.footer-nav ul li {
  margin: 0 15px;
}

.footer-nav ul li a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.footer-nav ul li a:hover {
  color: #5d3dfd;
}

.footer-social {
  margin-bottom: 20px;
}

.social-icon {
  color: #fff;
  font-size: 1.5em;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #5d3dfd;
}

.footer-bottom {
  border-top: 1px solid #444;
  padding-top: 20px;
}

.footer-bottom p {
  margin: 0;
  font-size: 0.9em;
  color: #bbb;
}

@media (max-width: 768px) {
  .footer-nav ul {
    flex-direction: column;
    align-items: center;
  }

  .footer-nav ul li {
    margin: 10px 0;
  }
}
@media (max-width: 768px) {
  .hero-section {
    min-height: auto;
    padding: 40px 20px;
  }

  .hero-logo {
    height: 50px;
  }

  .hero-text h1 {
    font-size: 2em;
  }

  .hero-text .hero-subtitle {
    font-size: 1.3em;
  }

  .hero-text p {
    font-size: 0.9em;
  }

  .cta-button {
    padding: 10px 20px;
    font-size: 0.9em;
  }
}
