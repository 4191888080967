/* src/components/common/GameNavbar.css */

.game-navbar {
  width: 100%;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: center; /* Center content */
  background-color: var(--navbar-bg, #5d3dfd);
  position: relative; /* To position logo and menu */
  box-sizing: border-box;
}

.navbar-logo {
  position: absolute;
  left: 20px;
  cursor: pointer; /* Add pointer cursor for clickability */
}

.navbar-logo .logo {
  height: 50px;
  border-radius: 8px;
  object-fit: cover;
}

.navbar-title {
  font-size: 2em; /* Increased font size */
  text-align: center;
  font-weight: bold; /* Make the text bold */
  text-transform: uppercase; /* Uppercase letters */
  letter-spacing: 2px; /* Add letter spacing */
  color: var(--navbar-title-text-color, #ffffff); /* Use configured color */
  /* Text shadow for depth */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.navbar-menu {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.navbar-menu svg {
  font-size: 1.5em;
  transition: color 0.3s;
  color: #000000; /* Default icon color */
}

.dropdown-menu {
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #ffffff;
  color: #5d3dfd;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 160px;
  z-index: 200;
  animation: fadeIn 0.3s ease-in-out;
}

.dropdown-item {
  padding: 12px 20px;
  text-decoration: none;
  color: #5d3dfd;
  cursor: pointer;
  transition: background 0.2s, color 0.2s;
}

.dropdown-item:hover {
  background-color: #9036d5;
  color: #ffffff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
