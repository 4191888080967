/* src/components/common/LoadingSpinner.css */

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(252, 252, 252, 0.8); /* Fondo semi-transparente */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Asegura que esté por encima de otros elementos */
}

.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner {
  border: 5px solid rgba(93, 61, 253, 0.2);
  border-radius: 50%;
  border-top: 5px solid #f82885;
  border-right: 5px solid #c42fad;
  width: 60px;
  height: 60px;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner p {
  margin-top: 15px;
  font-size: 1.2em;
  color: #5d3dfd;
  text-align: center;
}

@media (max-width: 768px) {
  .spinner {
    width: 40px;
    height: 40px;
  }
  .loading-spinner p {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .spinner {
    width: 30px;
    height: 30px;
  }
  .loading-spinner p {
    font-size: 0.9em;
  }
}
