/* src/components/games/MemoryMatchGame/MemoryMatchGame.module.css */

/* Define Brand Colors */
:root {
  --white: #fcfcfc;
  --blue: #5d3dfd;
  --purple: #9036d5;
  --pink-purple: #c42fad;
  --bright-pink: #f82885;
  --dark-overlay: rgba(0, 0, 0, 0.85);
  --gold: #ffd700;
  --silver: #c0c0c0;
  --bronze: #cd7f32;
  --shadow-color: rgba(0, 0, 0, 0.3);
  --font-family: "Montserrat", sans-serif;
  --button-hover: var(--pink-purple);
  --input-border: #ccc;
  --input-focus: var(--blue);
}

/* Import Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap");

/* Global box-sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Memory Match Game Container */
.memoryMatchGame {
  font-family: var(--font-family);
  background-color: var(--white);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Game Layout */
.gameLayout {
  display: flex;
  flex: 1;
}

/* Game Board Container */
.gameBoardContainer {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background-color: var(--gameboard-background-color, rgba(0, 0, 0, 0.05));
  position: relative;
  overflow: hidden;
}

/* Start Container */
.startContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px;
  animation: fadeIn 0.6s ease-out forwards;
  z-index: 2;
}

/* Fade In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Player Input */
.playerInput {
  padding: 20px 25px;
  font-size: 1.4em;
  border: 2px solid var(--input-border);
  border-radius: 12px;
  margin-bottom: 25px;
  width: 300px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.playerInput:focus {
  outline: none;
  border-color: var(--input-focus);
  box-shadow: 0 0 8px var(--input-focus);
}

/* Start Button */
.startButton {
  padding: 18px 35px;
  font-size: 1.5em;
  background-color: var(--blue);
  color: var(--white);
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.startButton:hover {
  background-color: var(--purple);
  transform: scale(1.07);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
}

/* Card Styles */
.card {
  width: 100%;
  height: 100%;
  perspective: 1000px;
  cursor: pointer;
  transition: transform 0.3s;
}

.cardInner {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  border: 3px solid var(--card-border-color, #000000);
  border-radius: 12px;
}

.flipped .cardInner {
  transform: rotateY(180deg);
}

.cardFront,
.cardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s, box-shadow 0.3s;
}

.cardFront {
  transform: rotateY(180deg);
}

.cardBack {
  transform: rotateY(0deg);
}

/* Game Board */
.gameBoard {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 10px;
  width: 100%;
  height: 100%;
  max-width: 1400px;
  max-height: 900px;
  position: relative;
  z-index: 1;
}

/* Game Finished Screen */
.gameFinished {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px;
  color: var(--purple);
  animation: fadeIn 0.6s ease-out forwards;
  text-align: center;
  z-index: 2;
}

.gameFinished h2 {
  font-size: 3em;
  color: var(--bright-pink);
  margin-bottom: 25px;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
}

.gameFinished p {
  font-size: 1.8em;
  color: var(--pink-purple);
  margin-bottom: 35px;
}

/* Responsive Styles */
@media (max-width: 1400px) {
  .gameBoardContainer {
    padding: 30px;
  }

  .gameBoard {
    max-width: 1200px;
    max-height: 800px;
    gap: 8px;
  }
}

@media (max-width: 1200px) {
  .gameBoard {
    max-width: 1000px;
    max-height: 700px;
    gap: 6px;
  }
}

@media (max-width: 992px) {
  .gameBoard {
    max-width: 900px;
    max-height: 600px;
    gap: 5px;
  }
}

@media (max-width: 768px) {
  .gameBoardContainer {
    padding: 25px;
  }

  .gameLayout {
    flex-direction: column;
  }

  .gameBoard {
    max-width: 800px;
    max-height: 550px;
    gap: 4px;
  }
}

@media (max-width: 480px) {
  .gameBoardContainer {
    padding: 15px;
  }

  .gameBoard {
    max-width: 100%;
    max-height: 100%;
  }

  .playerInput {
    width: 80%;
  }

  .startButton {
    width: 80%;
  }

  .gameFinished h2 {
    font-size: 2em;
  }

  .gameFinished p {
    font-size: 1.2em;
  }
}
