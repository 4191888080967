/* src/components/common/PlayerInfo.css */

.player-info {
  margin-bottom: 30px;
}

.player-info h2 {
  font-size: 2em;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
}

.info-item {
  font-size: 1.2em;
  margin: 10px 0;
  padding: 10px;
  background-color: rgba(252, 255, 252, 0.2);
  border-radius: 8px;
}

/* Responsive Styles */

@media (max-width: 1200px) {
  .player-info h2 {
    font-size: 1.8em;
  }

  .info-item {
    font-size: 1em;
  }
}

@media (max-width: 992px) {
  .player-info h2 {
    font-size: 1.6em;
  }

  .info-item {
    font-size: 0.95em;
  }
}

@media (max-width: 768px) {
  .player-info h2 {
    font-size: 1.4em;
  }

  .info-item {
    font-size: 0.9em;
  }
}

@media (max-width: 576px) {
  .player-info h2 {
    font-size: 1.2em;
  }

  .info-item {
    font-size: 0.85em;
  }
}

@media (max-width: 480px) {
  .player-info h2 {
    font-size: 1.0em;
  }

  .info-item {
    font-size: 0.8em;
  }
}
