/* src/components/Navbar.css */

.navbar {
  width: 100%;
  background-color: #1e1e2f;
  color: #ffffff;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  position: relative;
  z-index: 1000;
  height: 70px;
  display: flex;
  align-items: center;
}

.navbar-container {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Distribuye espacio entre los elementos */
}

/* Logo */
.logo-link {
  display: flex;
  align-items: center;
}

.logo {
  height: 50px;
  transition: transform 0.3s;
}

.logo:hover {
  transform: scale(1.1);
}

/* Menú de Navegación */
.nav-menu {
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.nav-menu.active {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  height: calc(100vh - 70px);
  background-color: #1e1e2f;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-link {
  margin-left: 25px;
  color: #ffffff;
  text-decoration: none;
  font-size: 1.1em;
  font-weight: 500;
  padding: 10px 15px;
  border-radius: 25px;
  transition: background 0.3s, color 0.3s;
}

.nav-link:hover {
  background-color: #5d3dfd;
  color: #ffffff;
}

.logout-button {
  background: none;
  border: 2px solid #ffffff;
  border-radius: 25px;
  padding: 8px 16px;
  margin-left: 25px;
  cursor: pointer;
  color: #ffffff;
  font-size: 1.1em;
  transition: background 0.3s, color 0.3s, border 0.3s;
}

.logout-button:hover {
  background-color: #f82885;
  border-color: #f82885;
  color: #ffffff;
}

/* Icono del Menú */
.menu-icon {
  display: none;
  cursor: pointer;
}

/* Dropdown del Usuario */
.user-menu {
  position: relative;
  margin-left: 20px;
}

.profile-picture-navbar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ffffff;
  cursor: pointer;
  transition: border 0.3s;
}

.profile-picture-navbar:hover {
  border-color: #f82885;
}

.dropdown-menu {
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #ffffff;
  color: #333333;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  width: 160px;
  z-index: 200;
  animation: fadeIn 0.3s ease-in-out;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  text-decoration: none;
  color: #333333;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  font-size: 0.95em;
}

.dropdown-item:hover {
  background-color: #5d3dfd;
  color: #ffffff;
}

.dropdown-item svg {
  margin-right: 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Styles */

@media (max-width: 1024px) {
  .nav-link {
    margin-left: 20px;
    font-size: 1em;
  }

  .logout-button {
    margin-left: 20px;
    padding: 6px 12px;
    font-size: 1em;
  }
}

@media (max-width: 768px) {
  .nav-menu {
    position: fixed;
    top: 70px;
    left: -100%;
    width: 100%;
    height: calc(100vh - 70px);
    background-color: #1e1e2f;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: left 0.3s ease-in-out;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-link {
    margin: 15px 0;
    font-size: 1.5em;
  }

  .logout-button {
    margin: 15px 0;
    padding: 10px 20px;
    font-size: 1.2em;
  }

  .menu-icon {
    display: block;
  }

  .user-menu {
    display: none;
  }
}

@media (max-width: 480px) {
  .navbar-container {
    padding: 0 15px;
  }

  .logo {
    height: 40px;
  }

  .dropdown-menu {
    width: 140px;
  }

  .dropdown-item {
    padding: 10px 18px;
  }
}
