/* src/components/common/Leaderboard.css */

.leaderboard-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.leaderboard-container h2 {
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
}

.leaderboard {
  flex-grow: 1;
  background-color: rgba(252, 255, 252, 0.2);
  border-radius: 10px;
  padding: 20px;
  box-shadow: inset 0 0 10px rgba(0,0,0,0.2);
  overflow-y: auto;
}

.leaderboard ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.leaderboard li {
  padding: 12px 10px;
  margin-bottom: 10px;
  background-color: rgba(93, 61, 253, 0.2);
  border-radius: 6px;
  font-size: 1.1em;
  transition: background-color 0.3s;
}

.leaderboard li:hover {
  background-color: rgba(93, 61, 253, 0.4);
}

/* Responsive Styles */

@media (max-width: 1200px) {
  .leaderboard-container h2 {
    font-size: 1.8em;
  }

  .leaderboard li {
    font-size: 1em;
  }
}

@media (max-width: 992px) {
  .leaderboard-container h2 {
    font-size: 1.6em;
  }

  .leaderboard li {
    font-size: 0.95em;
  }
}

@media (max-width: 768px) {
  .leaderboard-container h2 {
    font-size: 1.4em;
  }

  .leaderboard li {
    font-size: 0.9em;
  }
}

@media (max-width: 576px) {
  .leaderboard-container h2 {
    font-size: 1.2em;
  }

  .leaderboard li {
    font-size: 0.85em;
  }
}

@media (max-width: 480px) {
  .leaderboard-container h2 {
    font-size: 1.0em;
  }

  .leaderboard li {
    font-size: 0.8em;
  }
}
