/* General Styles */
body {
  margin: 0;
  font-family: "Arial", sans-serif;
  background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
}

.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.login-box {
  background: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 360px;
  position: relative;
  text-align: center;
}

.login-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background: linear-gradient(135deg, #ff7e7e 0%, #ff4c4c 100%); /* Rosa */
  border-radius: 10px 10px 0 0;
}

.login-box h2 {
  margin: 0 0 20px;
  color: #333;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.login-input {
  width: calc(100% - 20px);
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.login-input:focus {
  border-color: #2575fc;
  box-shadow: 0 0 8px rgba(37, 117, 252, 0.3);
  outline: none;
}

.login-button {
  width: 100%;
  padding: 15px;
  background-color: #2575fc;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.login-button:hover {
  background-color: #1d66d2;
  transform: translateY(-2px);
}

.login-button:active {
  background-color: #1a57c7;
  transform: translateY(0);
}

.login-footer {
  margin-top: 15px;
  font-size: 14px;
  color: #666;
}

.login-footer a {
  color: #2575fc;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.login-footer a:hover {
  color: #1d66d2;
}

/* Error Message Styles */
.error-message {
  background-color: #ff4c4c;
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 14px;
  box-sizing: border-box;
}
