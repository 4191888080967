/* src/components/common/Sidebar.css */

.sidebar {
  width: 250px;
  background: var(--sidebar-bg);
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 2px 0 12px rgba(0, 0, 0, 0.2);
}

.sidebar * {
  color: inherit;
}

.player-info h2 {
  font-size: 2em;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.info-item {
  font-size: 1.2em;
  margin: 10px 0;
  padding: 10px;
  background-color: rgba(252, 255, 252, 0.2);
  border-radius: 8px;
}

.leaderboard-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.leaderboard-container h2 {
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.leaderboard {
  flex-grow: 1;
  background-color: rgba(252, 255, 252, 0.2);
  border-radius: 10px;
  padding: 20px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
}

.leaderboard ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.leaderboard li {
  padding: 12px 10px;
  margin-bottom: 10px;
  background-color: rgba(93, 61, 253, 0.2);
  border-radius: 6px;
  font-size: 1.1em;
  transition: background-color 0.3s;
}

.leaderboard li:hover {
  background-color: rgba(93, 61, 253, 0.4);
}

/* Responsive Styles */

@media (max-width: 1024px) {
  .sidebar {
    width: 220px;
  }

  .player-info h2 {
    font-size: 1.8em;
  }

  .info-item {
    font-size: 1em;
  }

  .leaderboard-container h2 {
    font-size: 1.8em;
  }

  .leaderboard li {
    font-size: 1em;
  }
}

@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    left: -250px;
    top: 0;
    height: 100vh;
    z-index: 200;
    transition: left 0.3s ease-in-out;
  }

  .sidebar.active {
    left: 0;
  }
}

@media (max-width: 480px) {
  .sidebar {
    width: 200px;
    padding: 20px 15px;
  }

  .player-info h2 {
    font-size: 1.6em;
  }

  .leaderboard-container h2 {
    font-size: 1.6em;
  }

  .leaderboard li {
    font-size: 0.95em;
  }
}
