/* src/components/GameConfig/MemoryMatchFields.css */

.memory-match-fields {
  margin-top: 20px;
}

.memory-match-fields .form-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.memory-match-fields .form-group label {
  font-size: 1.2em;
  color: #495057;
  margin-bottom: 8px;
}

.memory-match-fields .form-group input[type="file"] {
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.memory-match-fields .form-group input[type="file"]:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.preview {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.preview img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 5px;
  margin-right: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.preview .btn-delete {
  background-color: #dc3545;
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
}

.preview .btn-delete:hover {
  background-color: #c82333;
}
