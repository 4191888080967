/* General Styles */
body {
  margin: 0;
  font-family: "Arial", sans-serif;
  background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
}

.register-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.register-box {
  background: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  position: relative;
  text-align: center;
}

.register-box h2 {
  margin: 0 0 20px;
  color: #333;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.form-group {
  text-align: left;
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #555;
}

.register-input {
  width: 100%;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.register-input:focus {
  border-color: #2575fc;
  box-shadow: 0 0 8px rgba(37, 117, 252, 0.3);
  outline: none;
}

.register-button {
  width: 100%;
  padding: 15px;
  background-color: #2575fc;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 10px;
}

.register-button:hover {
  background-color: #1d66d2;
  transform: translateY(-2px);
}

.register-button:active {
  background-color: #1a57c7;
  transform: translateY(0);
}

.register-footer {
  margin-top: 15px;
  font-size: 14px;
  color: #666;
}

.register-footer a {
  color: #2575fc;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.register-footer a:hover {
  color: #1d66d2;
}

/* Error Message Styles */
.error-message {
  background-color: #ff4c4c;
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 14px;
  box-sizing: border-box;
}

/* Profile Picture Preview */
.profile-picture-preview {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-top: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
