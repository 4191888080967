/* CSS Variables for Consistent Styling */
:root {
  --modal-background: #ffffff;
  --overlay-background: rgba(0, 0, 0, 0.5);
  --primary-color: #5d3dfd;
  --secondary-color: #9036d5;
  --confirm-button-bg: #4caf50;
  --confirm-button-hover-bg: #388e3c;
  --cancel-button-bg: #f44336;
  --cancel-button-hover-bg: #d32f2f;
  --input-border: #ccc;
  --input-focus-border: #5d3dfd;
  --font-family: 'Montserrat', sans-serif;
  --error-color: #f44336;
}

/* Overlay for the Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--overlay-background);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out forwards;
}

/* Modal Content */
.modal-content {
  background-color: var(--modal-background);
  border-radius: 10px;
  padding: 30px 20px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: slideDown 0.3s ease-out forwards;
  font-family: var(--font-family);
  outline: none;
}

/* Modal Header */
.modal-content h2 {
  margin-bottom: 20px;
  color: var(--primary-color);
  font-size: 1.8em;
}

/* Password Input */
.password-input {
  padding: 12px 15px;
  font-size: 1em;
  border: 2px solid var(--input-border);
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.password-input:focus {
  border-color: var(--input-focus-border);
  box-shadow: 0 0 5px var(--input-focus-border);
  outline: none;
}

/* Error Message */
.error-message {
  color: var(--error-color);
  margin-bottom: 10px;
  font-size: 0.9em;
}

/* Action Buttons Container */
.modal-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

/* Confirm Button */
.confirm-button {
  flex: 1;
  background-color: var(--confirm-button-bg);
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 12px 0;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.confirm-button:hover {
  background-color: var(--confirm-button-hover-bg);
  transform: translateY(-2px);
}

.confirm-button:active {
  transform: translateY(0);
}

/* Cancel Button */
.cancel-button {
  flex: 1;
  background-color: var(--cancel-button-bg);
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 12px 0;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.cancel-button:hover {
  background-color: var(--cancel-button-hover-bg);
  transform: translateY(-2px);
}

.cancel-button:active {
  transform: translateY(0);
}

/* Disabled Buttons */
.confirm-button:disabled,
.cancel-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  transform: none;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 480px) {
  .modal-content {
    padding: 20px 15px;
  }

  .modal-content h2 {
    font-size: 1.5em;
  }

  .password-input {
    font-size: 0.9em;
  }

  .confirm-button,
  .cancel-button {
    font-size: 0.9em;
    padding: 10px 0;
  }
}
