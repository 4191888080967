/* src/components/games/JigsawPuzzleGame/JigsawPuzzleGame.module.css */

/* Define Brand Colors */
:root {
  --white: #fcfcfc;
  --blue: #5d3dfd;
  --purple: #9036d5;
  --pink-purple: #c42fad;
  --bright-pink: #f82885;
  --dark-overlay: rgba(0, 0, 0, 0.85);
  --gold: #ffd700;
  --silver: #c0c0c0;
  --bronze: #cd7f32;
  --shadow-color: rgba(0, 0, 0, 0.3);
  --font-family: "Montserrat", sans-serif;
  --button-hover: var(--pink-purple);
  --input-border: #ccc;
  --input-focus: var(--blue);
}

/* Global Styles */
.jigsawPuzzleGame {
  font-family: var(--font-family);
  background-color: var(--white);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Game Layout */
.gameLayout {
  display: flex;
  flex: 1;
}

/* Game Board Container */
.gameBoardContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px;
  background: linear-gradient(135deg, #f0f0f0, #e0e0e0);
}

/* Start Container */
.startContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 8px 20px var(--shadow-color);
  animation: fadeIn 0.6s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Player Input */
.playerInput {
  padding: 15px 20px;
  font-size: 1.2em;
  border: 2px solid var(--input-border);
  border-radius: 10px;
  margin-bottom: 20px;
  width: 250px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.playerInput:focus {
  outline: none;
  border-color: var(--input-focus);
  box-shadow: 0 0 5px var(--input-focus);
}

/* Start Button */
.startButton {
  padding: 15px 30px;
  font-size: 1.3em;
  background-color: var(--blue);
  color: var(--white);
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.startButton:hover {
  background-color: var(--purple);
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4);
}

/* Jigsaw Game */
.jigsawGame {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

/* Puzzle Board */
.puzzleBoard {
  display: grid;
  grid-template-columns: repeat(3, 100px); /* Ajusta gridSize y tile size */
  grid-template-rows: repeat(3, 100px);
  gap: 2px;
  background-color: var(--white);
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0 8px 20px var(--shadow-color);
  background-image: linear-gradient(135deg, #f8f9fa, #e9ecef);
}

/* Puzzle Slot */
.puzzleSlot {
  width: 100px;
  height: 100px;
  border: 2px dashed var(--input-border);
  border-radius: 10px;
  position: relative;
  background-color: var(--white);
  transition: background-color 0.3s, border-color 0.3s;
}

.puzzleSlot:hover {
  background-color: #f1f1f1;
  border-color: var(--blue);
}

/* Puzzle Piece */
.puzzlePiece {
  width: 100px;
  height: 100px;
  background-size: 300px 300px; /* Ajusta según gridSize y tile size */
  cursor: grab;
  border: 2px solid var(--input-border);
  border-radius: 8px;
  transition: transform 0.3s, box-shadow 0.3s, border-color 0.3s;
}

.puzzlePiece:active {
  cursor: grabbing;
  transform: scale(1.05);
  box-shadow: 0 4px 15px var(--shadow-color);
  border-color: var(--purple);
}

/* Pieces Container */
.piecesContainer {
  display: flex;
  overflow-x: auto;
  max-width: 100%;
  padding: 15px 0;
  background-color: var(--white);
  border-radius: 15px;
  box-shadow: 0 8px 20px var(--shadow-color);
}

.scrollableContainer {
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 10px;
}

/* Draggable Puzzle Piece */
.draggablePiece {
  margin: 0;
  flex-shrink: 0;
}

/* Game Finished Screen */
.gameFinished {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centra el contenido verticalmente */
  background-color: rgba(255, 255, 255, 0.95);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 8px 20px var(--shadow-color);
  animation: fadeIn 0.6s ease-out forwards;
  text-align: center; /* Centra el texto internamente */
  max-width: 400px; /* Limita el ancho para evitar desbordamientos */
  width: 90%; /* Ancho relativo para responsividad */
}

.gameFinished h2 {
  font-size: 2.5em;
  color: var(--bright-pink);
  margin-bottom: 20px;
}

.gameFinished p {
  font-size: 1.5em;
  color: var(--pink-purple);
  margin-bottom: 30px;
}

.gameFinished .startButton {
  width: 200px;
}

/* Podium Overlay */
.podiumOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark-overlay);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .puzzleBoard {
    grid-template-columns: repeat(3, 90px);
    grid-template-rows: repeat(3, 90px);
    gap: 2px;
  }

  .puzzleSlot,
  .puzzlePiece {
    width: 90px;
    height: 90px;
  }

  .playerInput {
    width: 220px;
  }

  .startButton {
    width: 180px;
  }

  .gameFinished .playerInput {
    width: 250px;
  }

  .gameFinished .startButton {
    width: 180px;
  }
}

@media (max-width: 992px) {
  .puzzleBoard {
    grid-template-columns: repeat(3, 80px);
    grid-template-rows: repeat(3, 80px);
    gap: 2px;
  }

  .puzzleSlot,
  .puzzlePiece {
    width: 80px;
    height: 80px;
  }

  .piecesContainer {
    padding: 10px 0;
  }

  .scrollableContainer {
    gap: 10px;
  }

  .playerInput {
    width: 200px;
  }

  .startButton {
    width: 160px;
  }

  .gameFinished .playerInput {
    width: 220px;
  }

  .gameFinished .startButton {
    width: 160px;
  }
}

@media (max-width: 768px) {
  .puzzleBoard {
    grid-template-columns: repeat(3, 70px);
    grid-template-rows: repeat(3, 70px);
    gap: 1px;
  }

  .puzzleSlot,
  .puzzlePiece {
    width: 70px;
    height: 70px;
  }

  .piecesContainer {
    padding: 8px 0;
  }

  .scrollableContainer {
    gap: 8px;
  }

  .playerInput {
    width: 180px;
  }

  .startButton {
    width: 140px;
  }

  .gameFinished .playerInput {
    width: 200px;
  }

  .gameFinished .startButton {
    width: 140px;
  }

  .gameFinished h2 {
    font-size: 2em;
  }

  .gameFinished p {
    font-size: 1.2em;
  }
}

@media (max-width: 480px) {
  .jigsawPuzzleGame {
    padding: 10px;
  }

  .gameLayout {
    flex-direction: column;
  }

  .gameBoardContainer {
    padding: 10px;
  }

  .puzzleBoard {
    grid-template-columns: repeat(3, 60px);
    grid-template-rows: repeat(3, 60px);
    gap: 2px;
  }

  .puzzleSlot,
  .puzzlePiece {
    width: 60px;
    height: 60px;
  }

  .piecesContainer {
    padding: 6px 0;
  }

  .scrollableContainer {
    gap: 6px;
  }

  .playerInput {
    width: 160px;
  }

  .startButton {
    width: 120px;
  }

  .gameFinished .playerInput {
    width: 180px;
  }

  .gameFinished .startButton {
    width: 120px;
  }

  .gameFinished h2 {
    font-size: 2em;
  }

  .gameFinished p {
    font-size: 1.2em;
  }
}
 