/* components/ImageCropper.css */

.cropper-container {
    position: relative;
    margin: 20px 0;
  }
  
  .crop-button {
    background-color: #2575fc;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-top: 10px;
  }
  
  .crop-button:hover {
    background-color: #1d66d2;
    transform: translateY(-2px);
  }
  
  .crop-button:active {
    background-color: #1a57c7;
    transform: translateY(0);
  }
  