.event-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  color: #333;
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.event-card:hover {
  transform: scale(1.03);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.event-card h4 {
  margin: 0;
  font-size: 20px;
}

.event-card p {
  color: #666;
  margin: 10px 0;
}

.configure-button,
.delete-button {
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s;
  display: flex;
  align-items: center;
  gap: 8px;
}

.configure-button {
  background-color: #007bff;
  color: #ffffff;
}

.configure-button:hover {
  background-color: #0056b3;
}

.delete-button {
  background-color: #dc3545;
  color: #ffffff;
}

.delete-button:hover {
  background-color: #c82333;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal {
  position: relative;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  outline: none;
}

.modal-header {
  margin-bottom: 15px;
}

.modal-header h2 {
  margin: 0;
  font-size: 22px;
  color: #333;
}

.modal-body {
  margin-bottom: 20px;
}

.modal-body p {
  font-size: 16px;
  color: #666;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.modal-confirm,
.modal-cancel {
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.modal-confirm {
  background-color: #28a745;
  color: #ffffff;
}

.modal-confirm:hover {
  background-color: #218838;
}

.modal-cancel {
  background-color: #6c757d;
  color: #ffffff;
}

.modal-cancel:hover {
  background-color: #5a6268;
}
