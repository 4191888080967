/* src/components/Profile.css */

.profile-container {
  padding: 40px;
  background: linear-gradient(to right, #e3f2fd, #ffebee);
  color: #333;
  font-family: "Roboto", sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin-bottom: 40px;
}

.logout-button {
  background-color: #f44336;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 30px;
  transition: background-color 0.3s, transform 0.3s;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
}

.logout-button:hover {
  background-color: #d32f2f;
  transform: scale(1.05);
}

.profile-info {
  text-align: center;
  margin: 20px 0;
}

.profile-picture-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.camera-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.profile-picture-container:hover .camera-icon {
  opacity: 1;
}

.camera-icon-inner {
  font-size: 14px;
  text-align: center;
  padding: 5px;
}

.home-button {
  background-color: #2196f3;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 30px;
  transition: background-color 0.3s, transform 0.3s;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.home-button:hover {
  background-color: #1976d2;
  transform: scale(1.05);
}

.events-section {
  width: 100%;
  max-width: 800px;
  margin-top: 30px;
}

.event-card {
  background-color: #2196f3;
  color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.event-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

h2,
h3 {
  color: #333;
}

h2 {
  font-size: 32px;
  margin: 0;
}

h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

/* Modal Styles */
.crop-modal {
  background-color: #ffffff;
  padding: 20px;
  max-width: 600px;
  width: 90%;
  margin: auto;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
}

.crop-modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-title {
  text-align: center;
  margin-bottom: 20px;
}

.cropped-preview-container {
  text-align: center;
  margin-top: 20px;
}

.preview-title {
  margin-bottom: 10px;
}

.cropped-profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
}

.upload-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s;
}

.upload-button {
  background-color: #4caf50;
  color: #ffffff;
}

.upload-button:hover {
  background-color: #388e3c;
  transform: scale(1.05);
}

.cancel-button {
  background-color: #f44336;
  color: #ffffff;
}

.cancel-button:hover {
  background-color: #d32f2f;
  transform: scale(1.05);
}

/* ImageCropper Styles */
.cropper-container {
  width: 100%;
  max-height: 400px;
  overflow: hidden;
}

.crop-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #2196f3;
  color: #ffffff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s;
}

.crop-button:hover {
  background-color: #1976d2;
  transform: scale(1.05);
}
