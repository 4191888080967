.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.modal-content h2 {
  margin-top: 0;
  color: #333;
}

.modal-content p {
  color: #666;
  margin: 10px 0;
}

.modal-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.confirm-button,
.cancel-button {
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.confirm-button {
  background-color: #4caf50;
  color: #fff;
}

.confirm-button:hover {
  background-color: #388e3c;
}

.cancel-button {
  background-color: #f44336;
  color: #fff;
}

.cancel-button:hover {
  background-color: #d32f2f;
}
